import React, { useMemo } from 'react';
import './mapHeader.css';
import DropDownSelect from '../dropDownSelect';
import Loader from '../loader/loader';
import { useAppSelector } from '../../store/hooks';
import { selectCities, selectStatuses } from '../../store/slices/mapSlice';
import SearchField from '../searchField';

function MapHeader({
    selectedCity,
    selectedStatus,
    onSearch,
    onSelectCity,
    onSelectStatus,
}) {
    const validCities = useAppSelector(selectCities);
    const statuses = useAppSelector(selectStatuses);
    const validStatuses = useMemo(() => Object.values(statuses), [statuses]);

    const handleSelectStatus = (selected) => {
        const statusKey = Object.keys(statuses).find(
            (key) => statuses[key] === selected
        );
        onSelectStatus(statusKey || null);
    };

    return (
        <>
            <div className="ps-0 ps-md-4 me-sm-2 mb-0 w-100">
                <div className="d-md-flex w-100 justify-content-between align-items-center">
                    <h1 className="mt-0">Property Intelligence</h1>
                    <div className="ms-md-4 ps-lg-5 flex-md-grow-1 w-sm-100">
                        <SearchField onSearch={onSearch} />
                    </div>
                </div>
                <div className="col-xl-10 col-md-9 col-sm-12">
                    {!validCities || !validCities?.length ? (
                        <Loader />
                    ) : (
                        <div className="mt-1 row gap-1">
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <DropDownSelect
                                    options={[...validCities]}
                                    selected={selectedCity}
                                    onSelect={onSelectCity}
                                    label="All Cities"
                                />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <DropDownSelect
                                    options={validStatuses}
                                    selected={statuses[selectedStatus]}
                                    onSelect={handleSelectStatus}
                                    label="All Statuses"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MapHeader;
