import React, { useMemo } from 'react';

import Loading from '../../loading';
import Pill, { PillBackgroundColors } from '../../pill';
import { DashboardCardTitle, Dashboard_Not_Available_Label } from '..';
import CompsChartSelector from '../compCharts/compsChartSelector';
import {
    computeWeightedAvgSalePrice,
    countGoodComps,
} from '../../../utilities/scoutHelpers';

const CompsCard = ({ propComps, title, initialChartType }) => {
    const { isLoading, error, comps } = propComps;

    const avgSalePrice = useMemo(
        () => (comps?.length ? computeWeightedAvgSalePrice(comps) : null),
        [comps]
    );

    const renderCompValue = (value, label) => (
        <div className="w-100">
            <Pill label={value} compact background={PillBackgroundColors.LinenDark} />
            <div style={{ fontSize: '12px', textAlign: 'center' }}>{label}</div>
        </div>
    );

    const renderError = () => {
        console.log(title, 'comps error', error);
        return <p>Error retrieving property {title}</p>;
    };

    const nComps = comps.length ? countGoodComps(comps) : 'none';

    return (
        <div className="w-100">
            <DashboardCardTitle text={title} />
            <div className="w-100 d-flex">
                {isLoading ? (
                    <Loading />
                ) : error ? (
                    renderError()
                ) : (
                    <div className="w-100">
                        <div className="w-100 row align-items-start justify-content-between mb-2">
                            <div className="col-6">
                                {renderCompValue(
                                    avgSalePrice?.formatted || Dashboard_Not_Available_Label,
                                    'Avg Sale Price (Wt)'
                                )}
                            </div>
                            <div className="col-6 d-flex gap-2">
                                {renderCompValue('??', 'Days on Market')}
                                {renderCompValue(nComps, '# of Comps')}
                            </div>
                        </div>
                        <div
                            className="w-100 position-relative"
                            style={{ height: '160px', marginLeft: '-15px' }}
                        >
                            {!!comps.length && (
                                <CompsChartSelector
                                    comps={comps}
                                    initialChartType={initialChartType}
                                    avgSalePrice={avgSalePrice.value}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompsCard;
