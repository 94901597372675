import React, { useMemo } from 'react';
import {
    YAxis,
    XAxis,
    Tooltip,
    LineChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';
import { colors } from '../../../constants';
import { ReferenceLineSalePriceLabel, YAxisPriceTick } from './chartHelpers';
import { formatStreetAddress } from '../../../utilities/scoutHelpers';
import {
    formatDateMMYY,
    formatDateMMDDYY,
} from '../../../utilities/dateHelpers';
import './compCharts.css';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
        return (
            <div
                className="p-3 shadow scoutTooltipContainer"
                style={{ marginTop: '100px' }}
            >
                {payload.map((ele, index) => {
                    return (
                        <div key={index}>
                            <p className="small fw-bold text-capitalize">
                                {formatStreetAddress(ele.payload.address)}
                            </p>
                            <p className="small mb-0">
                ${ele.payload.sale_price.toLocaleString()}
                                <br />
                                {formatDateMMDDYY(new Date(ele.payload.sale_date))}
                            </p>
                        </div>
                    );
                })}
            </div>
        );
    }
    return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => {
    const date = new Date(payload.value);
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dx={-20}
                dy={8}
                textAnchor="center"
                fill={colors.reChartsTickGray}
                style={{ fontSize: '12px' }}
            >
                {formatDateMMYY(date)}
            </text>
        </g>
    );
};

const CompChartPriceByDate = ({ comps, avgSalePrice }) => {
    const chartData = useMemo(() => {
        let data = comps.filter(
            (comp) => comp.sale_date && Number(comp.sale_price) > 0
        );
        data.sort((a, b) =>
            new Date(a.sale_date) > new Date(b.sale_date) ? 1 : -1
        );
        data = data.map(({ address, sale_date, sale_price }) => {
            const date = new Date(sale_date);
            return {
                address: address,
                sale_date: date.getTime(),
                sale_price: Number(sale_price),
            };
        });
        return data;
    }, [comps]);

    const maxPrice = useMemo(
        () =>
            chartData.reduce(
                (acc, comp) => (comp.sale_price > acc ? comp.sale_price : acc),
                0
            ),
        [chartData]
    );
    const minPrice = useMemo(
        () =>
            chartData.reduce(
                (acc, comp) => (comp.sale_price < acc ? comp.sale_price : acc),
                999999999
            ),
        [chartData]
    );

    const yAxisMin = Math.max(minPrice - 500000, 0);
    const yAxisMax = maxPrice + 500000;

    if (!chartData?.length) {
        return null;
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width="100%"
                height="100%"
                data={chartData}
                margin={{
                    top: 5,
                    right: 20,
                    left: 0,
                    bottom: 5,
                }}
            >
                <XAxis
                    tick={<CustomizedXAxisTick />}
                    dataKey="sale_date"
                    domain={['auto', 'auto']}
                    name="Time"
                    type="number"
                ></XAxis>
                <YAxis domain={[yAxisMin, yAxisMax]} tick={<YAxisPriceTick />} />
                <Tooltip
                    content={<CustomTooltip active={false} payload={[]} label="" />}
                />
                <Line
                    type="monotone"
                    dataKey="sale_price"
                    stroke={colors.skyfallDark}
                />
                <ReferenceLine
                    y={avgSalePrice}
                    label={<ReferenceLineSalePriceLabel />}
                    stroke={colors.skyfall}
                    strokeWidth={0.5}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default CompChartPriceByDate;
