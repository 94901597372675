import { useEffect, useState } from 'react';
import { useLazyGetMapDataQuery } from '../store/slices/apiSlice/map-api-slice';
import { simpleObjectCompare } from '../utilities/objectHelpers';
import { MapQueryType } from '../utilities/mapHelpers';
import { toastWarning } from '../utilities/toast';

let lastRequest = {};

function useGetMapProperties() {
    const [apiData, setApiData] = useState();
    const [query, setQuery] = useState();
    const [mapPropertiesBounds, setMapPropertiesBounds] = useState([]);
    const [mapProperties, setMapProperties] = useState();
    const [getMapData, getMapDataResults] = useLazyGetMapDataQuery();

    useEffect(() => {
        if (query) {
            // TODO: this last query saving/comparison may not be necessary
            // RTK query may be ignoring earlier responses for us
            // (this is what it looks like from test observations and logging)
            lastRequest = query;
            console.debug(
                'Map Data Query',
                query?.search || 'no address',
                query?.city || 'no city',
                query?.bounds?.minLat || 'no min lat',
                query?.bounds?.maxLat || 'no max lat'
            );
            getMapData(query);
        }
    }, [query]);

    useEffect(() => {
        const { isError, status, isSuccess, data, originalArgs } =
      getMapDataResults;
        let timer = null;
        if (status === 'pending') {
            return;
        }

        const isLastRequest =
      originalArgs && lastRequest
          ? simpleObjectCompare(originalArgs, lastRequest)
          : true;
        console.log('Have response',{isLastRequest,getMapDataResults});
        if (isError) {
            console.log('Get Map Data error', isError, originalArgs, lastRequest);
            if (isLastRequest) {
                console.log('-- last request so retrying in 5 seconds');
                timer = setTimeout(() => {
                    toastWarning('Things are a little slow right now. Trying again...');
                    getMapData(query);
                    timer = null;
                }, 5000);
            }
        } else if (status === 'fulfilled' && isSuccess && isLastRequest) {
            console.log('Saving map data response for', lastRequest);
            setApiData(data);
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [getMapDataResults]);

    useEffect(() => {
        if (apiData == null) return;

        if (apiData?.view_extent && mapPropertiesBounds.length === 0) {
            const ve = apiData.view_extent;
            const newBounds =
        ve?.min_lon && ve.min_lat && ve.max_lon && ve.max_lat
            ? [
                [ve.min_lon, ve.min_lat],
                [ve.max_lon, ve.max_lat],
            ]
            : null;
            if (newBounds) {
                setMapPropertiesBounds(newBounds);
            }
        }
        setMapProperties(apiData.records);
    }, [apiData]);

    const queryByCity = (city, status) =>
        setQuery({
            type: MapQueryType.City,
            city,
            status,
        });

    const queryBySearch = (search, city) =>
        setQuery({
            type: MapQueryType.Search,
            search,
            city,
        });

    const queryByBounds = (bounds, city, status) =>
        setQuery({
            type: MapQueryType.Bounds,
            bounds,
            city,
            status,
        });

    return {
        queryByCity,
        queryBySearch,
        queryByBounds,
        mapPropertiesBounds,
        mapProperties,
    };
}

export default useGetMapProperties;
