import React from 'react';
import { colors } from '../../constants';
import ScoutButton from '../scoutButton';
import './mapPropertyCard.css';

const ONE_MILLION = 1000000;

// private Helper Components
const RankContainer = ({ rank }) => {
    const rankColor = rank <= 10 ? colors.goldwood : colors.skyfall;
    return (
        <div className="col-2 col-md-3 d-flex justify-content-center p-0 h-100">
            <div
                className="property-logo rounded-circle d-flex align-items-center justify-content-center"
                style={{ backgroundColor: rankColor }}
            >
                <div className="fw-bold text-white">{rank}</div>
            </div>
        </div>
    );
};

const PropertyDetailsContainer = ({ children }) => (
    <div className="col-10 col-md-9 d-flex flex-row flex-md-column justify-content-between gap-1 mt-1 pt-0 pb-0 propInfo text-capitalize">
        {children}
    </div>
);

const AddressContainer = ({ address, city }) => (
    <div>
        <div className="mapPropAddress lh-1 fw-bolder">{address.toLowerCase()}</div>
        <div className="mapPropAddress mt-1 lh-1 fst-italic">{city.toLowerCase()}</div>
    </div>
);

const ScoutSpreadContainer = ({ id, estimatedSpread }) => {
    const spreadInMillions =
    Math.round((estimatedSpread / ONE_MILLION) * 10) / 10;
    return (
        <div className="mt-md-1 d-flex flex-column flex-lg-row gap-0 gap-lg-2 align-items-center align-items-md-start align-items-lg-center pe-4 pe-lg-0">
            <ScoutButton propertyId={id} btnType="btn-secondary" compact={true} />
            {estimatedSpread > 0 && (
                <div className="fw-bold mapPropEstimatedSpread">
          ${spreadInMillions}M
                </div>
            )}
        </div>
    );
};

// Map Card
const MapPropertyCard = ({ id, address, city, rank, estimatedSpread }) => {
    return (
        <div
            id={id}
            className="ps-3 pe-1 py-2 rounded-3 mapPropCard d-flex align-items-center"
        >
            <RankContainer rank={rank} />

            <PropertyDetailsContainer>
                <AddressContainer address={address} city={city} />
                <ScoutSpreadContainer id={id} estimatedSpread={estimatedSpread} />
            </PropertyDetailsContainer>
        </div>
    );
};

export default MapPropertyCard;
