import React, { useEffect, useState } from 'react';
import Page from '../page';
import MapHeader from '../mapHeader';
import MapSidebar from '../mapSidebar/mapSidebar';
import { markerPopUp } from '../library/markerPopUp';
import Loader from '../loader/loader';
import './map.css';
import { useAppSelector } from '../../store/hooks';
import { selectAuthToken } from '../../store/slices/authSlice';
import useGetMapProperties from '../../hooks/useGetMapProperties';
import useMap, { MapControls } from '../../hooks/useMap';
import { useGetMapInitDataQuery } from '../../store/slices/apiSlice/map-api-slice';
import { colors } from '../../constants';

function Map() {
    const token = useAppSelector(selectAuthToken);
    const [selectedCity, setSelectedCity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState(null);
    const [sideBarProperties, setSideBarProperties] = useState(null);
    const [mapMoveEvent, setMapMoveEvent] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [sideBarPopup, setSideBarPopup] = useState(null);
    const { data: mapInitData } = useGetMapInitDataQuery();
    const { createMap, createPopup, addMarkers, map } = useMap();
    const {
        queryByCity,
        queryBySearch,
        queryByBounds,
        mapPropertiesBounds,
        mapProperties,
    } = useGetMapProperties();

    useEffect(() => {
        async function createMainMap() {
            let newMap = await createMap({
                containerId: 'map',
                center: [-120.0715, 37.3906],
                zoom: 6,
                controls: [
                    MapControls.Nav,
                    MapControls.SelfLocation,
                    MapControls.ToggleView,
                ],
            });
            newMap.on('moveend', function (e) {
                // only track user initiated events
                if (e?.originalEvent) {
                    setMapMoveEvent(e);
                }
            });
        }
        if (token) {
            createMainMap();
        }
    }, [token]);

    useEffect(() => {
        if (mapMoveEvent == null) return;
        refreshMap();
    }, [mapMoveEvent]);

    useEffect(() => {
        if (mapPropertiesBounds && mapPropertiesBounds.length) {
            map.fitBounds(mapPropertiesBounds);
        }
    }, [mapPropertiesBounds]);

    useEffect(() => {
        if (mapInitData) {
            queryByCity('', selectedStatus);
        }
    }, [mapInitData]);

    useEffect(() => {
        if (loading) return;
        setLoading(true);
        refreshMap();
    }, [selectedCity, selectedStatus]);

    useEffect(() => {
        if (searchValue == null) return;
        setLoading(true);
        if (searchValue === '') {
            queryByCity(selectedCity, selectedStatus);
        } else {
            queryBySearch(searchValue, selectedCity);
        }
    }, [searchValue]);

    useEffect(() => {
        if (!mapProperties) {
            return;
        }
        createMarkers(mapProperties);
        let sortedArray = mapProperties.slice(0, 20).map((element) => element);
        setSideBarProperties(sortedArray);
        setLoading(false);
    }, [mapProperties]);

    function refreshMap() {
        let { _sw, _ne } = map.getBounds();
        queryByBounds(
            { minLat: _sw.lat, maxLat: _ne.lat, minLon: _sw.lng, maxLon: _ne.lng },
            selectedCity,
            selectedStatus
        );
    }

    function createMarkers(locations) {
        const markerLocations = locations.map((property, index) => ({
            color: index < 10 ? colors.goldwood : colors.skyfall,
            longitude: Number(property.longitude),
            latitude: Number(property.latitude),
            popUpWrapper: markerPopUp(property),
        }));
        addMarkers(markerLocations, true);
    }

    function clearPopUp(element) {
        let markerHighlight = document.getElementsByClassName('maplibregl-popup');
        if (markerHighlight.length > 0) {
            markerHighlight[0].style.display = 'none';
        }
        element.remove();
    }

    function popupOnSideClick(targetId) {
        if (sideBarPopup != null) clearPopUp(sideBarPopup);
        let element = sideBarProperties[targetId];
        let markerPopupWrapper = markerPopUp(element);
        let popup = createPopup(element, markerPopupWrapper);
        setSideBarPopup(popup);
        popup.addTo(map);
    }

    return (
        <Page>
            <div className="min-vh-100 pb-2 d-flex flex-column">
                <MapHeader
                    selectedCity={selectedCity}
                    selectedStatus={selectedStatus}
                    onSearch={setSearchValue}
                    onSelectCity={setSelectedCity}
                    onSelectStatus={setSelectedStatus}
                />
                <div
                    className={`ps-0 ps-md-4 mt-2 ${
                        loading ? 'opacity-25' : 'opacity-100'
                    }`}
                >
                    <div className="row gx-0">
                        <div className="col-12 col-md-9 col-xl-10">
                            <div className="map rounded-3" id="map" />
                        </div>
                        <div className="col-12 col-md-3 col-xl-2">
                            {!(sideBarProperties == null || sideBarProperties.length == 0) ? (
                                <MapSidebar
                                    properties={sideBarProperties}
                                    onPropertyClick={popupOnSideClick}
                                />
                            ) : (
                                <div className="fw-bold px-3">
                                    {loading ? 'Loading Properties...' : 'No Records Found.'}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {loading && <Loader />}
        </Page>
    );
}

export default Map;
