import { useState } from 'react';
import { useLazyGetPropertyQuery } from '../../store/slices/apiSlice/scout-api-slice';

function useGetMLSHistory() {
    const [histories, setHistories] = useState({});
    const [getProperty] = useLazyGetPropertyQuery();

    const fetchHistories = (propIds) => {
        propIds.forEach((id) => fetchHistory(id));
    };

    const fetchHistory = (propId) => {
        getProperty(propId)
            .unwrap()
            .then((fulfilled) => {
                // test on fulfilled should not be needed anymore but leaving it in for safety.
                // it was previously required for side effect when put scout invalidated a pending get scout...
                if (fulfilled) {
                    setHistories((h) => ({ ...h, [propId]: fulfilled.MLSHistory }));
                }
            })
            .catch((rejected) => console.error(propId, rejected));
    };

    return [histories, fetchHistories];
}

export default useGetMLSHistory;
