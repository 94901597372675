import React, { useEffect, useState } from 'react';
import Loader from '../loader/loader';
import Page from '../page';
import DropDownSelect from '../dropDownSelect';
import { useAppSelector } from '../../store/hooks';
import {
    useLazyGetKanbanQuery,
    useGetMapInitDataQuery,
} from '../../store/slices/apiSlice/map-api-slice';
import { usePutPropertyScoutFieldsMutation } from '../../store/slices/apiSlice/scout-api-slice';
import KanbanBoard from '../kanbanBoard/kanbanBoard';
import { selectCities, selectSources } from '../../store/slices/mapSlice';
import {
    transformResponseToPropsByStatus,
    STATUS_NOT_FEASIBLE,
} from '../../utilities/kanbanHelpers';
import { handleBackendError } from '../../utilities/errorHandling';
import { getKeyByValue } from '../../utilities/objectHelpers';
import { toastWarning } from '../../utilities/toast';
import useGetMLSHistory from '../../hooks/useGetMLSHistory';

const MAX_RESULTS = 200;

function Kanban() {
    const [mlsHistories, fetchMLSHistories] = useGetMLSHistory();
    const [getKanbanData, getKanbanDataResults] = useLazyGetKanbanQuery();
    const [putPropertyScoutFields, putPropertyScoutFieldsResult] =
    usePutPropertyScoutFieldsMutation();
    const { isLoading: mapInitIsLoading, error: initError } =
    useGetMapInitDataQuery();
    const validCities = useAppSelector(selectCities);
    const sourcesDict = useAppSelector(selectSources);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const [propertiesByStatus, setPropertiesByStatus] = useState({});
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const validSources = React.useMemo(
        () => Object.values(sourcesDict),
        [sourcesDict]
    );

    const getKanban = () =>
        getKanbanData({
            maxResults: MAX_RESULTS,
            city: selectedCity,
            source: selectedSource
                ? getKeyByValue(sourcesDict, selectedSource)
                : null,
        });

    useEffect(() => {
        getKanban();
    }, [selectedCity, selectedSource]);

    useEffect(() => {
        const { isError, status, isSuccess, data } = getKanbanDataResults;
        let timer = null;
        if (isError) {
            timer = setTimeout(() => {
                toastWarning('Things are a little slow right now. Trying again...');
                getKanban();
                timer = null;
            }, 5000);
        } else if (status === 'fulfilled' && isSuccess) {
            setPropertiesByStatus(transformResponseToPropsByStatus(data));
            setIsInitialLoad(false);
            if (data.records.length >= MAX_RESULTS) {
                toastWarning(
                    `More than ${MAX_RESULTS} properties in Kanban. Some properties maybe not displayed.`
                );
            }
            fetchMLSHistories(data.records.map((prop) => prop.id));
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [getKanbanDataResults]);

    useEffect(() => {
        if (putPropertyScoutFieldsResult.isError) {
            handleBackendError('Put Scout Fields', putPropertyScoutFieldsResult);
        }
    }, [putPropertyScoutFieldsResult]);

    function updatePropertyStatus(property, newStatus) {
        const { starred, source } = property;
        let psfDict = {
            starred: starred || false,
            status: newStatus,
            source: source,
        };
        putPropertyScoutFields({ propertyId: property.id, scoutFields: psfDict });
    }

    function onSelectCity(newCity) {
        setSelectedCity(newCity || null);
    }

    function onSelectSource(value) {
        setSelectedSource(value || null);
    }

    const findProperty = (statusId, propertyId) => {
        const sourceColumn = propertiesByStatus[statusId];
        const property = sourceColumn.find(
            (prop) => String(prop.id) === propertyId
        );
        return property;
    };

    const updatePropertiesByStatus = (propertyToMove, action) => {
        const { source, destination } = action;
        const sourceColumnId = source?.statusId;
        const destinationColumnId = destination?.statusId;

        if (sourceColumnId && sourceColumnId === destinationColumnId) {
            const updatedColumn = [...propertiesByStatus[sourceColumnId]];
            updatedColumn.splice(source.index, 1);
            updatedColumn.splice(destination.index, 0, propertyToMove);
            setPropertiesByStatus({
                ...propertiesByStatus,
                [source.statusId]: updatedColumn,
            });
        } else {
            const sourceColumn = [...propertiesByStatus[sourceColumnId]];
            const destinationColumn = [...propertiesByStatus[destinationColumnId]];
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, propertyToMove);
            setPropertiesByStatus({
                ...propertiesByStatus,
                [source.statusId]: sourceColumn,
                [destination.statusId]: destinationColumn,
            });
        }
    };

    const handleChangeStatus = (propertyId, action) => {
        const property = findProperty(action.source.statusId, propertyId);
        // optimistically updating displayed properties
        updatePropertiesByStatus(property, action);
        updatePropertyStatus(property, Number(action.destination.statusId));
    };

    const handleChangeOrder = (propertyId, action) => {
        const property = findProperty(action.source.statusId, propertyId);
        updatePropertiesByStatus(property, action);
    };

    const handleNotFeasible = (propertyId, statusId) => {
        const property = findProperty(statusId, propertyId);
        // optimistically updating displayed properties
        const sourceColumn = propertiesByStatus[statusId];
        const propertyIndex = sourceColumn.findIndex(
            (prop) => prop.id == propertyId
        );
        if (propertyIndex >= 0) {
            sourceColumn.splice(propertyIndex, 1);
            const newPropertiesByStatus = {
                ...propertiesByStatus,
                [statusId]: sourceColumn,
            };
            setPropertiesByStatus(newPropertiesByStatus);
            updatePropertyStatus(property, STATUS_NOT_FEASIBLE);
        }
    };

    const renderError = (errorInfo, dataType) => {
        console.log(`Error retrieving ${dataType}`, errorInfo);
        return <p>Error retrieving {dataType} data</p>;
    };

    return (
        <Page>
            <div className="min-vh-100 d-flex ps-0 ps-md-4 pe-2 pe-sm-0 pe-xs-0 flex-column">
                <div className="mb-2">
                    <div className="pb-2">
                        <h1>Property Pipeline</h1>
                    </div>
                    <div className="py-2 d-flex gap-2">
                        {mapInitIsLoading ? (
                            <Loader />
                        ) : initError ? (
                            renderError(initError, 'Map Init')
                        ) : (
                            <>
                                <div className="col-lg-2 col-md-2 col-sm-4 mb-2">
                                    <DropDownSelect
                                        options={validSources}
                                        selected={selectedSource}
                                        onSelect={onSelectSource}
                                        label="Any Source"
                                    />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-4 mb-2">
                                    <DropDownSelect
                                        options={[...validCities]}
                                        selected={selectedCity}
                                        onSelect={onSelectCity}
                                        label="All Cities"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {isInitialLoad ? (
                    <Loader />
                ) : getKanbanDataResults.isError ? (
                    renderError(getKanbanDataResults, 'Kanban')
                ) : (
                    <KanbanBoard
                        propertiesByStatus={propertiesByStatus}
                        mlsHistories={mlsHistories}
                        onChangeOrder={handleChangeOrder}
                        onChangeStatus={handleChangeStatus}
                        onNotFeasible={handleNotFeasible}
                    />
                )}
            </div>
        </Page>
    );
}

export default Kanban;
