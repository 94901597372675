import React, { useMemo } from 'react';
import {
    Label,
    YAxis,
    XAxis,
    Tooltip,
    ComposedChart,
    ReferenceLine,
    Scatter,
    ResponsiveContainer,
} from 'recharts';
import { ReferenceLineSalePriceLabel, YAxisPriceTick } from './chartHelpers';
import { colors } from '../../../constants';
import { formatStreetAddress } from '../../../utilities/scoutHelpers';
import './compCharts.css';

const CustomTooltip = ({ active, payload, comps }) => {
    if (active && payload?.length) {
        const ttPayload = payload[0].payload;
        const wt = ttPayload.weight;
        const compsForWt = comps.filter((comp) => comp.weight === wt);
        return (
            <div
                className="p-3 shadow scoutTooltipContainer"
                style={{ marginTop: '100px' }}
            >
                <p className="small">Weight:&nbsp;{wt}</p>

                {compsForWt.map((comp, index) => (
                    <div key={index}>
                        <span className="small fw-bold text-capitalize">
                            {formatStreetAddress(comp.address)}
                        </span>
            &nbsp;
                        <span className="small">${comp.sale_price.toLocaleString()}</span>
                    </div>
                ))}
            </div>
        );
    }
    return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dx={-4}
                dy={8}
                textAnchor="center"
                fill={colors.reChartsTickGray}
                style={{ fontSize: '12px' }}
            >
                {payload.value}
            </text>
        </g>
    );
};

const CompChartPriceByWeight = ({ comps, avgSalePrice }) => {
    const chartData = useMemo(() => {
        let data = comps.filter(
            (comp) => comp.weight && Number(comp.sale_price) > 0
        );
        data.sort((a, b) => (a.weight > b.weight ? 1 : -1));
        data = data.map(({ address, weight, sale_price }) => ({
            address: address,
            weight: weight,
            sale_price: Number(sale_price),
        }));
        return data;
    }, [comps]);

    const chartLists = useMemo(() => {
        if (chartData.length < 1) {
            return [];
        }
        const weightDictionary = {};
        chartData.forEach((comp) => {
            const wt = comp.weight;
            if (weightDictionary[wt]) {
                weightDictionary[wt].push(comp.sale_price);
            } else {
                weightDictionary[wt] = [comp.sale_price];
            }
        });
        const lists = [];
        for (const wt in weightDictionary) {
            const data = { weight: Number(wt) };
            weightDictionary[wt].forEach(
                (p, index) => (data[`comp${index + 1}`] = p)
            );
            lists.push(data);
        }
        return lists;
    }, [chartData]);

    // maxComp will be used to build an array [0,1...maxComp-1] which will be used to render scatter diatgrams
    // Each scatter diagram render will look for data with key comp<n> (ie. 1st chart renders comp1 values,
    // 2nd chart is comp2 values, etc.)
    const maxComp = useMemo(() => {
        let max = 0;
        // Each list has a prop/key for weight and then a prop/key for each comp at that weight (comp1...compn)
        chartLists.forEach((list) => {
            const nComps = Object.keys(list).length - 1;
            max = Math.max(max, nComps);
        });
        return max;
    }, [chartLists]);

    const maxPrice = useMemo(
        () =>
            chartData.reduce(
                (acc, comp) => (comp.sale_price > acc ? comp.sale_price : acc),
                0
            ),
        [chartData]
    );
    const minPrice = useMemo(
        () =>
            chartData.reduce(
                (acc, comp) => (comp.sale_price < acc ? comp.sale_price : acc),
                999999999
            ),
        [chartData]
    );
    const yAxisMin = Math.max(minPrice - 500000, 0);
    const yAxisMax = maxPrice + 500000;

    const minWeight = chartLists[0].weight;
    const maxWeight = chartLists[chartLists.length - 1].weight;
    const xAxisMin = Math.max(minWeight - 5, 0);
    const xAxisMax = Math.min(maxWeight + 5, 100);

    if (!chartData.length) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                width="100%"
                height="100%"
                data={chartLists}
                margin={{
                    top: 5,
                    right: 20,
                    left: 0,
                    bottom: 10,
                }}
            >
                <Tooltip
                    content={
                        <CustomTooltip
                            active={false}
                            payload={[]}
                            label=""
                            comps={chartData}
                        />
                    }
                />
                <XAxis
                    domain={[xAxisMin, xAxisMax]}
                    dataKey={'weight'}
                    tick={<CustomizedXAxisTick />}
                >
                    <Label position="insideBottom" offset="0" fontSize={14}>
            Weight
                    </Label>
                </XAxis>
                <YAxis domain={[yAxisMin, yAxisMax]} tick={<YAxisPriceTick />} />
                {[...Array(maxComp).keys()].map((compNumber) => (
                    <Scatter
                        key={`scatter${compNumber}`}
                        dataKey={`comp${compNumber + 1}`}
                        fill={colors.skyfallDark}
                    />
                ))}
                <ReferenceLine
                    y={avgSalePrice}
                    label={<ReferenceLineSalePriceLabel />}
                    stroke={colors.skyfall}
                    strokeWidth={0.5}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default CompChartPriceByWeight;
