import React from 'react';
import { ChartType,
} from '../compCharts/compsChartSelector';
import CompsCard from './compsCard';

const AcquisitionCompsCard = ({ property }) => {
    return (
        <CompsCard
            title="Acquisition Comps"
            propComps={property.acquisitionComps}
            initialChartType={ChartType.Date}
        />
    );
};

export default AcquisitionCompsCard;
