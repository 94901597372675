import React from 'react';

import { ChartType } from '../compCharts/compsChartSelector';
import CompsCard from './compsCard';

const SaleCompsCard = ({ property: { saleComps } }) => {
    return (
        <CompsCard
            title="Sale Comps"
            propComps={saleComps}
            initialChartType={ChartType.Weight}
        />
    );
};

export default SaleCompsCard;
